import { Component, OnInit } from "@angular/core";
import { AppService } from "src/app/services/app/app.service";

@Component({
  selector: "app-channels",
  templateUrl: "./channels.component.html",
  styleUrls: ["./channels.component.css"],
})
export class ChannelsComponent implements OnInit {
  lang: string;

  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.appService.getAppLang().subscribe((language) => {
      this.lang = language;
    });
  }
}
