<div class="row mb-4">
  <div class="col">
    <form [formGroup]="driverMoreDetailsFormGroup">
      <!-- ----------------------------------------------------------------------- -->
      <!--                       Medical Conditons questions                       -->
      <!-- ----------------------------------------------------------------------- -->

      <div class="row">
        <div class="col-lg-10 col-md-8 col-sm-9">
          <label class="question-lable">
            {{ "QuotationRequestForms.MoreDetails.Conditions" | translate }}
            <i class="icon ion-md-help-circle tooltip-icon" placement="top" [ngbTooltip]="
            'QuotationRequestForms.MoreDetails.ConditionsMessage' | translate
          " aria-hidden="true" style="width: 20px">
            </i>
          </label>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-3">
          <div class="tg-list d-flex justify-content-end">
            <button type="button" class="btn btn-sm" [class.btn-primary]="!isCollapsed"
              [class.btn-outline-primary]="isCollapsed" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
              aria-controls="collapseExample">
              {{choose}}
            </button>
            <!-- <div class="tg-list-item">
              <input class="tgl tgl-flip" id="cb3" type="checkbox" (change)="showMedicalConditions()" />
              <label [attr.data-tg-off]="!showDriverMedical ? choose : choose"
                [attr.data-tg-on]="showDriverMedical ? pass : pass" class="tgl-btn" for="cb3"></label>
              <i class="ion-md-arrow-dropdown ion-md-arr" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
                aria-controls="collapseExample"></i>
            </div> -->
          </div>
        </div>
      </div>
      <!-- /Medical Conditons questions -->

      <!-- ----------------------------------------------------------------------- -->
      <!--                      Medical Conditons checkboxes                       -->
      <!-- ----------------------------------------------------------------------- -->

      <div class="row" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
        <!-- spinner -->

        <div *ngIf="!stepsData.MedicalConditions" class="loader-card col d-flex justify-content-center">
          <div class="content-container loader-wrapper flex-centered flex-container">
            <div class="lds-facebook flex-centered">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4" *ngFor="let medical of stepsData.MedicalConditions; let i = index">
          <input class="cbx" type="checkbox" [id]="medical?.name + i" style="display: none"
            (change)="addMedicalCondition(medical.id)" />
          <label for="{{ medical.name + i }}" class="check pt-2 pb-2">
            <svg width="18px" height="18px" viewBox="0 0 20 18">
              <path
                d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z">
              </path>
              <polyline points="1 9 7 14 15 4"></polyline>
            </svg>
            <span> {{ lang === "ar" ? medical.nameAr : medical.name }} </span>
          </label>
        </div>
      </div>
      <!-- /Medical Conditons checkboxes -->

      <!-- ----------------------------------------------------------------------- -->
      <!--                              Violations Q                               -->
      <!-- ----------------------------------------------------------------------- -->

      <!--  <hr>
            <div class="row">
                <div class="col-lg-10 col-md-8 col-sm-9">
                    <label class="question-lable">
                        {{ 'QuotationRequestForms.MoreDetails.Violations' | translate }}</label>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-3">
                    <input type="checkbox" id="m-driverViolation" class="question-checkbox"
                     (change)="showViolations()" /><label
                        for="m-driverViolation"><span class="ui"></span></label>
                </div>
            </div>
            <div *ngIf="isViolationCheckboxEmpty" class="v-meassage  pt-2 pb-2">
                {{ 'Errors.ViolationInvalid' | translate }}
            </div> -->

      <!-- ----------------------------------------------------------------------- -->
      <!--                          Violations checkboxes                          -->
      <!-- ----------------------------------------------------------------------- -->
      <!--  <div *ngIf="showDriverViolation">

                <div class="row">

                    <div class="spinner-border" role="status" *ngIf="!stepsData.MedicalConditions">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4"
                        *ngFor="let violation of stepsData.violations; let i = index;">
                        <input class="cbx" type="checkbox" [id]="i + violation.name" style="display: none;"
                         (change)="addViolation(violation.id)">
                        <label for="{{i + violation.name}}" class="check pt-2 pb-2">
                            <svg width="18px" height="18px" viewBox="0 0 20 18">
                                <path
                                    d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z">
                                </path>
                                <polyline points="1 9 7 14 15 4"></polyline>
                            </svg>
                            <span> {{ lang === 'ar' ? violation.nameAr : violation.name }}</span>
                        </label>
                    </div>
                </div>
            </div> -->
    </form>
  </div>
</div>