<div class="quote-card boxed-item row">
  <!-- ----------------------------------------------------------------------- -->
  <!--                       PRODUCT INFO (Right Column [ar])                  -->
  <!-- ----------------------------------------------------------------------- -->
  <div class="quote-company-container" [ngClass]="timer.isOver ? 'disabled-quote-company noselect dark-c' : ''">
    <!--- Company Logo --------------------------------------------------->
    <!-------------------------------------------------------------------->
    <div class="quote-image-wrapper">
      <img *ngIf="quote?.insuranceCompany.logo" [src]="insuranceCompanyLogo + quote?.insuranceCompany.logo"
        class="quote-image" />
    </div>

    <!--- Terms and conditions ------------------------------------------->
    <!-------------------------------------------------------------------->
    <a class="download-btn text-light" target="_blank" [href]="FileUrl + quote?.products[0].conditionUrl"><i
        class="ion-md-cloud-download mx-1"></i>
      {{ "Footer.Terms" | translate }}</a>

    <!-- Details View Button --------------------------------------------->
    <!-------------------------------------------------------------------->
    <a (click)="isViewingDetails = !isViewingDetails" href="javascript:void(0)"
      class="quote-details-btn btn btn-primary" [ngClass]="timer.isOver ? 'disabled-company-detail-btn' : ''">
      {{
      (isViewingDetails ? "App.CloseText" : "App.ViewMoreText") | translate
      }}
    </a>

    <!-- Timer Over ----------------------------------------------------->
    <!------------------------------------------------------------------->
    <div *ngIf="timer.isOver" class="timer-over-wrapper">
      {{ "Quote.TimerOverText" | translate }}
    </div>

    <!-- Timer --------------------------------------------------------->
    <!------------------------------------------------------------------>
    <div *ngIf="!timer.isOver" class="quote-timer-wrapper">
      <div class="quote-timer-entry">
        <div id="timer-val">{{ timer.seconds }}</div>
        <div id="timer-text">{{ "App.SecondsText" | translate }}</div>
      </div>
      <div class="quote-timer-entry">
        <div id="timer-val">{{ timer.minutes }}</div>
        <div id="timer-text">{{ "App.MinutesText" | translate }}</div>
      </div>
      <div class="quote-timer-entry">
        <div id="timer-val">{{ timer.hours }}</div>
        <div id="timer-text">{{ "App.HoursText" | translate }}</div>
      </div>
    </div>
  </div>

  <!-- ----------------------------------------------------------------------- -->
  <!--                   PRODUCT CONTENTS (Left Column [ar])                   -->
  <!-- ----------------------------------------------------------------------- -->
  <div class="quote-content-container" [ngClass]="timer.isOver ? 'disabled-quote-product noselect dark' : ''">
    <!-- EXPIRY ALERT --------------------------------------------------------->
    <!------------------------------------------------------------------------->
    <div *ngIf="timer.isOver" class="expired-quote-alert flex-column">
      <svg width="204" height="109" viewBox="0 0 274 179" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M128.422 4.68751C132.825 -0.798437 141.175 -0.798424 145.578 4.68753L270.734 160.614C276.513 167.814 271.388 178.5 262.156 178.5H11.844C2.61219 178.5 -2.51313 167.814 3.26562 160.614L128.422 4.68751Z"
          fill="#007bef" />
        <path
          d="M131.648 70.3H141.812L139.997 129.59H133.342L131.648 70.3ZM136.73 155.605C134.955 155.605 133.423 155 132.132 153.79C130.922 152.499 130.317 151.007 130.317 149.313C130.317 147.538 130.922 146.086 132.132 144.957C133.342 143.747 134.875 143.142 136.73 143.142C138.505 143.142 139.997 143.747 141.207 144.957C142.417 146.086 143.022 147.538 143.022 149.313C143.022 151.007 142.417 152.499 141.207 153.79C139.997 155 138.505 155.605 136.73 155.605Z"
          fill="white" />
      </svg>
      <h2>{{ "Quote.TimerOverText" | translate }}</h2>
    </div>

    <!-- PRODUCT DETAILS ------------------------------------------------------>
    <!------------------------------------------------------------------------->
    <div class="quote-details">
      <!-- Product Title + Comparison Button -------------------------------->
      <!--------------------------------------------------------------------->
      <div class="quote-top-section">
        <!-- Title -->
        <div class="quote-company-name">
          <h4>{{ lang == "ar" ? quoteProduct.nameAr : quoteProduct.name }}</h4>
        </div>
        <!-- Comparison Button -->
        <div *ngIf="!quote.isSelectedForOrdering">
          <a (click)="toggleProductComparison(popoverEl)" #popoverEl="ngbPopover" triggers="manual"
            [ngbPopover]="popContent" class="quote-compare-btn" style="height: 100%" [ngClass]="
              quoteProduct.isUnderComparison ? 'under-comparison-btn' : ''
            " href="javascript:void(0)">
            <i class="ion-ios-apps"></i>
            <span *ngIf="!quoteProduct.isUnderComparison">{{
              "Quote.CompareButtonText" | translate
              }}</span>
            <span *ngIf="quoteProduct.isUnderComparison">{{
              "Quote.RemoveFromComparisonText" | translate
              }}</span>
          </a>
          <ng-template #popContent>
            <span class="comparison-alert-popover">{{
              "Quote.QuoteComparison.MaxComparisonAlertText" | translate
              }}</span>
          </ng-template>
        </div>
      </div>

      <!-- Quote Benefits + Extra Benefits + Discounts ------------------------------>
      <!----------------------------------------------------------------------------->
      <div class="quote-details-container">
        <!-- Free Benefits -->
        <div class="quote-details-block" *ngIf="!isDeductibleLoaderVisible">
          <div class="row p-0 mb-4" style="height: 15px">
            <div class="col-lg-6">
              <p class="group-title">
                {{ "Quote.BenefitsHeaderTitle" | translate }}
              </p>
            </div>
            <div class="col-lg-6" *ngIf="noOfViewedFreeBenefits >= 4">
              <button (click)="toggleFreeBenefits()" class="btn showMore-btn">
                <i [ngClass]="
                    isBtnShown ? 'ion-md-arrow-dropup' : 'ion-md-arrow-dropdown'
                  " style="margin-left: 5px; margin-right: 5px"></i>
                <span>معاينة الكل</span>
              </button>
            </div>
          </div>
          <ul>
            <div *ngFor="let benefit of freeBenefits; let i = index">
              <div *ngIf="i < noOfViewedFreeBenefits">
                <li *ngIf="benefit.benefitTypeId === 2" class="quote-benefit">
                  <i class="ion-ios-checkmark" style="color: teal"></i>
                  {{ lang === "en" ? benefit.name : benefit.nameAr }}
                </li>
              </div>
            </div>
          </ul>
          <!-- Empty free Benefits -->
        </div>

        <!-- Extra Benefits -->

        <div *ngIf="
            !quoteProduct?.isSelected &&
            quoteProduct.hasExtraBenefits &&
            !isDeductibleLoaderVisible
          " class="quote-details-block extra-benefits-block">
          <div class="row p-0 mb-3" style="height: 15px">
            <div class="col">
              <p class="group-title">
                {{ "Quote.ExtraBenefitsTitle" | translate }}
                <i class="icon ion-md-help-circle tooltip-icon" placement="top" [ngbTooltip]="
            'QuotationRequestForms.Tooltips.ExtraBenefits' | translate
          " aria-hidden="true" style="width: 20px"></i>
              </p>
            </div>
            <!-- <div class="col d-flex justify-content-end" *ngIf="noOfViewedExtraBenefits >= 5">
                            <button (click)="toggleExtraBenefits()" class="btn showMore-btn">
                                <i [ngClass]="isBtnShown ? 'ion-md-arrow-dropup':'ion-md-arrow-dropdown'"
                                    style="margin-left: 5px; margin-right: 5px;"></i>
                                <span>معاينة الكل</span>
                            </button>
                        </div> -->
          </div>
          <div>
            <table>
              <tr *ngFor="let benefit of extraBenefits; let i = index">
                <!-- <div *ngIf="i < noOfViewedExtraBenefits"> -->
                <div>
                  <div class="row">
                    <div class="quote-detail-item-title col-lg-9">
                      <input [checked]="
                          benefit.benefitTypeId == 3 ||
                          benefit.isChecked ||
                          quote.isSelectedForOrdering
                        " [disabled]="
                          benefit.benefitTypeId == 3 ||
                          quote.isSelectedForOrdering ||
                          (quote?.insuranceCompany.id == 5 &&
                            benefit.benefitId == 1 &&
                            uiState.gulfGeneralIC.selectedExtraBenefit ===
                              'PassengerCover') ||
                          (quote?.insuranceCompany.id == 5 &&
                            benefit.benefitId == 3 &&
                            uiState.gulfGeneralIC.selectedExtraBenefit ===
                              'DriverCover') ||
                          (quote?.insuranceCompany.id == 6 &&
                            benefit.benefitId == 57 &&
                            uiState.gulfGeneralIC.selectedExtraBenefit ===
                              'PassengerCover') ||
                          (quote?.insuranceCompany.id == 6 &&
                            benefit.benefitId == 58 &&
                            uiState.gulfGeneralIC.selectedExtraBenefit ===
                              'DriverCover') ||
                              (quote?.insuranceCompany.id == 7 &&
                            benefit.benefitId == 1 &&
                            uiState.alraghiTakaful.selectedExtraBenefit ===
                              'PassengerCover') ||
                              (quote?.insuranceCompany.id == 7 &&
                            benefit.benefitId == 3 &&
                            uiState.alraghiTakaful.selectedExtraBenefit ===
                              'DriverCover')
                        " (change)="addExtraBenefit(benefit)" class="cbx" type="checkbox" [id]="randomBenefitId + i"
                        style="display: none" />
                      <label [for]="randomBenefitId + i" class="check noselect">
                        <svg width="18px" height="18px" viewBox="0 0 20 18">
                          <path
                            d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z">
                          </path>
                          <polyline points="1 9 7 14 15 4"></polyline>
                        </svg>
                        <span class="quote-benefit">
                          {{ lang == "ar" ? benefit.nameAr : benefit.name }}
                        </span>
                      </label>
                    </div>

                    <div *ngIf="!(benefit.benefitTypeId === 2)" class="col-lg-3 mt-2">
                      <span class="benefit-price">
                        {{ benefit.benefitAmount | number : "1.2-2" }}
                        <small>{{ "App.Currency" | translate }}</small>
                      </span>
                    </div>
                  </div>
                </div>
              </tr>
            </table>
          </div>

          <!-- <div class="row">
                        <div class="col d-flex justify-content-center">
                             <div  class="down-arrow-round" (click)="toggleExtraBenefits()"></div>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div> -->
        </div>

        <!-- Empty Extra Benefits Text -->
        <div *ngIf="!quoteProduct.hasExtraBenefits" class="quote-details-block extra-benefits-block flex-container">
          <p class="group-title">
            {{ "Quote.ExtraBenefitsTitle" | translate }}
          </p>
          <div class="flex-container flex-centered" style="flex-direction: column">
            <i class="ion-ios-alert flex-centered" style="font-size: 50px; color: #007bef"></i>
            <h6 class="flex-centered">
              {{ "App.UnavailableText" | translate }}
            </h6>
          </div>
        </div>

        <!-- Selected Quote Extra Benefits (disabled checkboxes) -->
        <div *ngIf="quoteProduct?.isSelected && quoteProduct.hasExtraBenefits"
          class="quote-details-block extra-benefits-block">
          <p class="group-title">
            {{ "Quote.ExtraBenefitsTitle" | translate }}
          </p>
          <div>
            <table>
              <tr *ngFor="let benefit of quoteProduct?.benefits; let i = index">
                <th style="width: 80%" *ngIf="
                    !(benefit.benefitTypeId === 2) &&
                    benefit.isSelected &&
                    benefit.isChecked
                  ">
                  <div class="quote-detail-item-title">
                    <input checked="checked" disabled="disabled" class="cbx" type="checkbox"
                      [id]="quote?.quotationReqtId + quoteProduct.name + i" style="display: none" />
                    <label [for]="quote?.quotationReqtId + quoteProduct.name + i" class="check noselect">
                      <svg width="18px" height="18px" viewBox="0 0 20 18">
                        <path
                          d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z">
                        </path>
                        <polyline points="1 9 7 14 15 4"></polyline>
                      </svg>
                      <span>
                        {{
                        lang == "ar"
                        ? benefit.nameAr
                        : (benefit.name | number : "1.2.2")
                        }}
                      </span>
                    </label>
                  </div>
                </th>
                <td *ngIf="!(benefit.benefitTypeId === 2) && benefit.isChecked" style="width: 20%">
                  <span class="benefit-price">
                    {{ benefit.benefitAmount | number : "1.2-2" }}
                    <small>{{ "App.Currency" | translate }}</small>
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <!-- Premium Breakdowns + Discounts -->
        <div class="quote-details-block" *ngIf="!isDeductibleLoaderVisible">
          <!-- Title -->
          <p class="group-title">{{ "Quote.DiscountsTitle" | translate }}</p>

          <!-- Premium Breakdowns -->
          <div class="premium-breakdowns">
            <table>
              <!-- <tr
                                *ngFor="let pmbd of quoteProduct.selectedDeductible?.premiumBreakdowns | SortBy:'asc':'name'; let i = index">
                                <th style="width: 70%;">
                                    <div class="quote-detail-item-title">
                                        <span> {{ lang=='ar' ? pmbd.nameAr : pmbd.name }} </span>
                                    </div>
                                </th>
                                <td style="width: 30%;">
                                    <span class="cost-details-price benefit-price">
                                        {{ pmbd.breakdownAmount | number : '1.2-2' }}
                                        <small>{{ 'App.Currency' | translate }}</small>
                                    </span>
                                </td>
                            </tr> -->
              <tr>
                <th style="width: 70%">
                  <label>
                    <span style="font-weight: 20; font-size: 15px">
                      {{ "Quote.ExtraBenefitsTitle" | translate }}</span>
                  </label>
                </th>
                <td class="benefit-price">
                  <span class="price">
                    {{
                    quoteProduct.selectedDeductible?.totalAdditionalBenefits
                    | number : "1.2-2"
                    }}
                    <small class="currency-style">{{
                      "App.Currency" | translate
                      }}</small>
                  </span>
                </td>
              </tr>
              <tr *ngFor="
                  let pmbd of quoteProduct.selectedDeductible?.premiumBreakdowns
                    | SortBy : 'asc' : 'breakdownPercentage';
                  let i = index
                ">
                <td style="width: 70%">
                  <label class="text-style-label" *ngIf="pmbd.breakdownTypeId == 4">
                    <span> {{ lang == "ar" ? pmbd.nameAr : pmbd.name }}</span>
                  </label>
                  <label class="text-style-label" *ngIf="pmbd.breakdownTypeId == 6">
                    <span [ngClass]="pmbd.breakdownTypeId == 6 ? 'total' : 'vat'">
                      {{
                      "Checkout.netPremiumWithoutBenefits" | translate
                      }}</span>
                  </label>
                  <label *ngIf="pmbd.breakdownTypeId == 5">
                    <span class="text-style-label">
                      {{ lang == "ar" ? pmbd.nameAr : pmbd.name }} ({{
                      pmbd.breakdownPercentage * 100 + "%"
                      }})
                    </span>
                  </label>
                </td>
                <td style="width: 30%">
                  <span class="benefit-price" *ngIf="
                      pmbd.breakdownTypeId != 4 && pmbd.breakdownTypeId != 3
                    ">
                    {{ pmbd.breakdownAmount | number : "1.2-2" }}
                    <small>{{ "App.Currency" | translate }}</small>
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  {{ "Quote.Deductibles.TotalAmountBeforeDiscount" | translate }}
                </td>
                <td>
                  <span class="benefit-price">
                    {{ quoteProduct.selectedDeductible?.policyPremium +
                    getTotalDiscount(quoteProduct.selectedDeductible?.discounts) | number : "1.2-2" }}
                    <small>{{ "App.Currency" | translate }}</small>
                  </span>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <hr class="my-1" />
                </td>
              </tr>

              <ng-container *ngFor="
              let discount of quoteProduct.selectedDeductible?.discounts;
              let i = index
            ">
                <tr *ngIf="discount.discountTypeId === 2">
                  <th style="width: 70%">
                    <div class="quote-detail-item-title">
                      <span style="font-weight: 700;">
                        {{ lang == "ar" ? discount.nameAr : discount.name }}
                      </span>
                    </div>
                  </th>
                  <td style="width: 30%">
                    <span class="cost-details-price benefit-price">
                      ({{ discount.discountAmount | number : "1.2-2" }}
                      <small>{{ "App.Currency" | translate }}</small>)
                    </span>
                  </td>
                </tr>
              </ng-container>
            </table>
          </div>
          <!-- <hr
            *ngIf="quoteProduct.selectedDeductible?.discounts.length > 0 && quoteProduct.selectedDeductible?.discounts.discountTypeId === 2" /> -->
          <hr class="my-1" *ngIf="DiscountTypes()">
          <!-- Discounts -->
          <div class="discounts">
            <table>
              <ng-container *ngFor="
                  let discount of quoteProduct.selectedDeductible?.discounts;
                  let i = index
                ">
                <tr *ngIf="discount.discountTypeId !== 2">
                  <th style="width: 70%">
                    <div class="quote-detail-item-title">
                      <span>
                        {{ lang == "ar" ? discount.nameAr : discount.name }}
                      </span>
                    </div>
                  </th>
                  <td style="width: 30%">
                    <span class="cost-details-price benefit-price">
                      ({{ discount.discountAmount | number : "1.2-2" }}
                      <small>{{ "App.Currency" | translate }}</small>)
                    </span>
                  </td>
                </tr>
              </ng-container>
            </table>
          </div>
        </div>

        <!-- Deductible Change Loader/Spinner -->
        <div class="text-center" *ngIf="isDeductibleLoaderVisible">
          <div class="spinner-border d-spinner" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>

      <!-- Quote Bottom Section ----------------------------------------------------->
      <!----------------------------------------------------------------------------->
      <div class="quote-bottom-section">
        <!-- Liability ------------------------------------------------------------>
        <!------------------------------------------------------------------------->
        <div class="quote-liability-container quote-action">
          <!-- Liability Text -->
          <div class="liability-text" *ngIf="
              !(quoteProduct.productTypeId === 1) &&
              !(quoteProduct.productTypeId === 3) &&
              !(quoteProduct.productTypeId === 5) &&
              quoteProduct.deductibles
            ">
            <span style="margin: auto">
              {{ "Quote.LiabilityCostText" | translate }}
            </span>
          </div>

          <!-- Liability Amount Select -->
          <div class="liability-cost" *ngIf="
              !(quoteProduct.productTypeId === 1) &&
              !(quoteProduct.productTypeId === 3) &&
              !(quoteProduct.productTypeId === 5) &&
              quoteProduct.deductibles
            ">
            <ng-select class="quote-liability" [searchable]="false"
              [ngClass]="timer.isOver ? 'disabled-liability-select' : ''"
              [(ngModel)]="quoteProduct.selectedDeductible.deductibleValue" (change)="setSelectedDeductible($event)"
              [items]="quoteProduct.deductibles" bindValue="deductibleValue" bindLabel="deductibleValue"
              [clearable]="false">
            </ng-select>
          </div>
        </div>

        <!-- Empty Section -->
        <div class="bottom-empty-section d-flex justify-content-center aligin-items-center">
          <div class="mt-3">
            <span class="benefit-price" *ngIf="quote?.products[0].isVehicleSumInsuredUpdated == true">
              القيمة التقديرية للمركبةالمعدلة من شركة التامين
              {{ quote?.products[0].icVehicleSumInsured | number : "1.2-2"
              }}<small class="mx-1">{{ "App.Currency" | translate }}</small>
            </span>
          </div>
        </div>

        <!-- Price + Buy Button --------------------------------------------------->
        <!------------------------------------------------------------------------->
        <div class="purchase-section">
          <!-- Price Text -->
          <div class="purchase-total-wrapper quote-action">
            <span style="margin: auto">
              {{ "App.TotalPriceText" | translate }}
            </span>
          </div>

          <!-- Hidden Price Text (On Quote Preview After Order/Select) -->
          <div *ngIf="quote.isSelectedForOrdering">
            <div class="purchase-price-wrapper quote-action">
              <span style="margin: auto">
                {{ "App.TotalPriceText" | translate }}
              </span>
            </div>
          </div>

          <!-- Price Amount ----------------------------------------------------->
          <!--------------------------------------------------------------------->
          <div class="purchase-price-wrapper quote-price quote-action flex-column">
            <!-- Previous Price -->
            <!-- <span *ngIf="quoteProduct.previousTotalPremium && quoteProduct.previousTotalPremium !== quoteProduct.selectedDeductible.policyPremium" id="previous-price" class="flex-centered">
                            <s>
                                {{ quoteProduct.previousTotalPremium | number : '1.2-2' }}
                                <small>{{ 'App.Currency' | translate }}</small>
                            </s>
                        </span> -->

            <!-- Current Price -->
            <span id="price" class="flex-centered" style="font-weight: bold">
              {{
              quoteProduct.selectedDeductible.policyPremium | number : "1.2-2"
              }}
              <small>{{ "App.Currency" | translate }}</small>
            </span>
          </div>

          <!-- Buy Button -->
          <div *ngIf="!quote.isSelectedForOrdering">
            <button (click)="orderQuoteProduct()" [disabled]="isLoading"
              class="purchase-btn-wrapper quote-submit quote-action" style="width: 100%">
              <span style="margin: auto" class="bouncy">
                <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                  style="margin: 5px; color: #fff"></span>
                <i class="ion-ios-checkmark-circle"></i>
                {{ "App.BuyText" | translate }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- COMPANY DETAILS ------------------------------------------------------>
    <!------------------------------------------------------------------------->
    <div class="company-details" [ngClass]="
        isViewingDetails ? 'shown-company-details' : 'hidden-company-details'
      ">
      <div class="row">
        <!-- Name/Description -->
        <div class="col-sm-7 col-md-6">
          <h4>
            {{
            lang == "ar"
            ? quote?.insuranceCompany.nameAr
            : quote?.insuranceCompany.name
            }}
          </h4>
          <p>
            {{
            lang == "ar"
            ? quote?.insuranceCompany.descriptionAr
            : quote?.insuranceCompany.description
            }}
          </p>
        </div>
        <!-- <div class="boder"></div> -->
        <!-- Contact -->

        <div class="col-lg-6">
          <h4>{{ "Quote.CompanyDetails.ContactTitleText" | translate }}</h4>
          <table class="company-contact">
            <tr>
              <th>
                <i class="ion-ios-call" style="margin: 10px"></i>{{ "Quote.CompanyDetails.PhoneTitleText" | translate }}
              </th>
              <td>{{ quote?.insuranceCompany.phoneNumber }}</td>
            </tr>
            <tr>
              <th>
                <i class="ion-ios-mail" style="margin: 10px"></i>{{ "Quote.CompanyDetails.EmailTitleText" | translate }}
              </th>
              <td>{{ quote?.insuranceCompany.email }}</td>
            </tr>
            <tr>
              <th>
                <i class="ion-ios-globe" style="margin: 10px"></i>{{ "Quote.CompanyDetails.WebsiteTitleText" | translate
                }}
              </th>
              <td>{{ quote?.insuranceCompany.website }}</td>
            </tr>
            <tr>
              <th>
                <i class="ion-ios-navigate" style="margin: 10px"></i>{{ "Quote.CompanyDetails.AddressTitleText" |
                translate }}
              </th>
              <td>{{ quote?.insuranceCompany.address }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>