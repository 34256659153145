<form [formGroup]="vehicleInfoFormGroup">
  <div class="row">
    <div class="col-sm-12 col-md-2 col-lg-2 flex-container center">
      <div class="flex-centered mt-12">
        <div class="round round-lg hollow color" style="margin: 10px auto">
          <span>{{ !requestFromProfile ? "3" : "2" }}</span>
        </div>
        <div class="logo-wrapper">
          <h6>
            {{ "QuotationRequestForms.StepsTitles.VehicleInfo" | translate }}
          </h6>
          <img src="assets/images/car.svg" class="logo" />
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-md-9 col-lg-9 border-right border-left border-bottom">
      <div class="row">
        <!-- ----------------------------------------------------------------------- -->
        <!--                         vehicleSumInsured Input                         -->
        <!-- ----------------------------------------------------------------------- -->

        <div class="col-sm-12 col-md-6 col-lg-4">
          <label>
            {{
            "QuotationRequestForms.VehicleInfo.EstimatedVehicleValue"
            | translate
            }}
            <i class="icon ion-md-help-circle tooltip-icon" placement="top" [ngbTooltip]="
            'QuotationRequestForms.Tooltips.VehicleEstimatedValue' | translate
          " aria-hidden="true" style="width: 20px">
        </i>
          </label>
          <div class="input-group">
            <input onKeyPress="if(this.value.length==9) return false" type="text" min="0" class="form-control"
              [placeholder]="'Landing.VehicleEstimatedValue' | translate" formControlName="vehicleSumInsured" matInput
              mask="separator .2" thousandSeparator="," [ngClass]="{
                'is-invalid': submitted && f.vehicleSumInsured.errors
              }" />
            <div class="input-group-prepend">
              <span class="input-group-text currency-txt">
                {{
                "QuotationRequestForms.VehicleInfo.CurrencyText" | translate
                }}</span>
                
            </div>
            <span class="invalid-feedback">
              {{ estimatedVehicleValueMessage }}
            </span>
            <div *ngIf="submitted && f.vehicleSumInsured.errors" class="invalid-feedback">
              <div *ngIf="f.vehicleSumInsured.errors?.required">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
              <div *ngIf="f.vehicleSumInsured.errors?.wrongSomeInsured">
                يجب أن تكون قيمة المركبة أكبر من 50,000
              </div>
            </div>
          </div>
        </div>

        <!-- ------------------------ VehicleRepairMethods Input ------------------------- -->

        <div class="col-sm-12 col-md-6 col-lg-4">
          <label>{{
            "QuotationRequestForms.InsuranceInfo.PlaceOfManufacture" | translate
            }}</label>
          <div class="form-group">
            <ng-select [items]="stepsData.VehicleRepairMethods" [loading]="!stepsData.VehicleRepairMethods"
              formControlName="vehicleRepairMethodId" bindValue="id" [searchFn]="termSearchFn" [ngClass]="{
                'is-invalid': submitted && f.vehicleRepairMethodId.errors
              }">
              <ng-template ng-label-tmp let-item="item">
                <span>{{ lang === "ar" ? item.nameAr : item.name }}</span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                <span>{{ lang === "ar" ? item.nameAr : item.name }}</span>
              </ng-template>
            </ng-select>
            <div *ngIf="f.vehicleRepairMethodId.errors">
              <div class="v-meassage" *ngIf="
                  (submitted && f.vehicleRepairMethodId.errors.required) ||
                  (f.vehicleRepairMethodId.touched &&
                    f.vehicleRepairMethodId.errors.required)
                ">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
            </div>
          </div>
        </div>
        <!-- ----------------------------------------------------------------------- -->
        <!--                           vehicleUseId Input                            -->
        <!-- ----------------------------------------------------------------------- -->

        <div class="col-sm-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>
              {{
              "QuotationRequestForms.VehicleInfo.PurposeOfVehicleUse"
              | translate
              }}
            </label>
            <ng-select [items]="stepsData.VehicleUses" bindValue="id" formControlName="vehicleUseId"
              [loading]="!stepsData.VehicleUses" [searchFn]="termSearchFn"
              [ngClass]="{ 'ng-invalid': submitted && f.vehicleUseId.errors }">
              <ng-template ng-label-tmp let-item="item">
                <span>{{ lang === "ar" ? item.nameAr : item.name }}</span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                <span>{{ lang === "ar" ? item.nameAr : item.name }}</span>
              </ng-template>
            </ng-select>
            <div *ngIf="submitted && f.vehicleUseId.errors">
              <div class="v-meassage" *ngIf="f.vehicleUseId.errors.required">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
            </div>
          </div>
        </div>

        <!-- ----------------------------------------------------------------------- -->
        <!--                    vehicleTransmissionTypeId  Input                     -->
        <!-- ----------------------------------------------------------------------- -->

        <div class="col-sm-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>
              {{
              "QuotationRequestForms.VehicleInfo.TransmissionType" | translate
              }}
            </label>
            <ng-select [items]="stepsData.TransmissionTypes" bindValue="id" formControlName="vehicleTransmissionTypeId"
              [loading]="!stepsData.TransmissionTypes" [searchFn]="termSearchFn" [ngClass]="{
                'ng-invalid': submitted && f.vehicleTransmissionTypeId.errors
              }">
              <ng-template ng-label-tmp let-item="item">
                <span>{{ lang === "ar" ? item.nameAr : item.name }}</span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                <span>{{ lang === "ar" ? item.nameAr : item.name }}</span>
              </ng-template>
            </ng-select>
            <div *ngIf="submitted && f.vehicleTransmissionTypeId.invalid">
              <div class="v-meassage" *ngIf="f.vehicleTransmissionTypeId.errors.required">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
            </div>
          </div>
        </div>

        <!-- ----------------------------------------------------------------------- -->
        <!--                     vehicleParkingLocationId  Input                     -->
        <!-- ----------------------------------------------------------------------- -->

        <div class="col-sm-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>{{
              "QuotationRequestForms.VehicleInfo.VehicleParking" | translate
              }}
            </label>
            <ng-select [items]="stepsData.ParkingLocations" bindValue="id" formControlName="vehicleParkingLocationId"
              [loading]="!stepsData.ParkingLocations" [searchFn]="termSearchFn" [ngClass]="{
                'ng-invalid': submitted && f.vehicleParkingLocationId.errors
              }">
              <ng-template ng-label-tmp let-item="item">
                <span>{{ lang === "ar" ? item.nameAr : item.name }}</span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                <span>{{ lang === "ar" ? item.nameAr : item.name }}</span>
              </ng-template>
            </ng-select>
            <div *ngIf="submitted && f.vehicleParkingLocationId.errors">
              <div class="v-meassage" *ngIf="f.vehicleParkingLocationId.errors.required">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
            </div>
          </div>
        </div>

        <!-- ----------------------------------------------------------------------- -->
        <!--                         isVehicleModified input                         -->
        <!-- ----------------------------------------------------------------------- -->

        <div class="col-sm-6 col-md-4 col-lg-4">
          <label>{{ "QuotationRequestForms.VehicleInfo.modifications" | translate }}
            <i class="icon ion-md-help-circle tooltip-icon" placement="top" [ngbTooltip]="
            'QuotationRequestForms.Tooltips.Vehiclemodifications' | translate
          " aria-hidden="true" style="width: 20px"></i>
          </label>
          <div class="form-group">
            <label>
              <input type="radio" class="option-input radio" [value]="true" (change)="vehicleIsUpdated()"
                formControlName="isVehicleModified" />
              <span class="radio-text">
                {{ "QuotationRequestForms.VehicleInfo.Yes" | translate }}
              </span>
            </label>

            <label>
              <input type="radio" class="option-input radio" formControlName="isVehicleModified" [value]="false"
                (change)="vehicleIsUpdated()" />
              <span class="radio-text">
                {{ "QuotationRequestForms.VehicleInfo.No" | translate }}</span>
            </label>
          </div>
        </div>
      </div>

      <!-- ----------------------------------------------------------------------- -->
      <!--                    modificationsDescription textarea                    -->
      <!-- ----------------------------------------------------------------------- -->

      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="!vehicleUpdated">
          <div class="form-group">
            <label>
              {{
              "QuotationRequestForms.VehicleInfo.modificationsDescription"
              | translate
              }}
            </label>
            <textarea class="form-control" rows="2" formControlName="vehicleModificationDetails" [ngClass]="{
                'is-invalid': submitted && f.vehicleModificationDetails.errors
              }"></textarea>
            <div *ngIf="submitted && f.vehicleModificationDetails.errors">
              <div class="v-meassage" *ngIf="f.vehicleModificationDetails.errors.required">
                {{ "Errors.EmptyInputField" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ----------------------------------------------------------------------- -->
      <!--                     vehicleSpecification questions                      -->
      <!-- ----------------------------------------------------------------------- -->

      <div class="row mt-4">
        <div class="col-lg-10 col-md-8 col-sm-9">
          <label class="question-lable">
            {{
            "QuotationRequestForms.MoreDetails.VehicleSpecification"
            | translate
            }}</label>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-3">
          <div class="tg-list d-flex justify-content-end">
            <!-- <div class="tg-list-item">
              <input class="tgl tgl-flip" id="cb2" type="checkbox" (change)="showVehicleSpecifications()" />
              <label [attr.data-tg-off]="Vehiclepecifications ? choose : choose"
                [attr.data-tg-on]="!Vehiclepecifications ? pass : pass" class="tgl-btn" for="cb2"></label>
            </div> -->
            <button type="button" class="btn btn-sm" [class.btn-primary]="!isCollapsed"
              [class.btn-outline-primary]="isCollapsed" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
              aria-controls="collapseExample">
              {{choose}}
            </button>
          </div>
        </div>
      </div>
      <!--//  vehicleSpecification questions -->

      <!-- ----------------------------------------------------------------------- -->
      <!--                     vehicleSpecification checkboxes                     -->
      <!-- ----------------------------------------------------------------------- -->

      <div class="row mb-4" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
        <div *ngIf="!stepsData.VehicleSpecifications" class="loader-card col d-flex justify-content-center">
          <div class="content-container loader-wrapper flex-centered flex-container">
            <div class="lds-facebook flex-centered">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4" *ngFor="
            let VehicleSpecification of stepsData.VehicleSpecifications;
            let i = index
          ">
          <input class="cbx" type="checkbox" [id]="VehicleSpecification?.name + i" style="display: none"
            [checked]="VehicleSpecification.checked" (change)="addVehicleSpecification(VehicleSpecification.id)" />
          <label for="{{ VehicleSpecification.name + i }}" class="check pt-2 pb-2">
            <svg width="18px" height="18px" viewBox="0 0 20 18">
              <path
                d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z">
              </path>
              <polyline points="1 9 7 14 15 4"></polyline>
            </svg>
            <span>
              {{
              lang === "ar"
              ? VehicleSpecification.nameAr
              : VehicleSpecification.name
              }}
            </span>
          </label>
        </div>
      </div>
    </div>

    <!-- ----------------------------------------------------------------------- -->
    <!--                               Check icon                                -->
    <!-- ----------------------------------------------------------------------- -->
    <div class="flex-centered mt-12 col-md-1">
      <div class="col-sm-12 col-md-1 col-lg-1">
        <div [ngClass]="{
            'check-icon': vehicleInfoFormGroup.valid,
            'check-icon-invalid': vehicleInfoFormGroup.invalid
          }">
          <i class="icon ion-md-checkmark-circle"></i>
        </div>
      </div>
    </div>
  </div>
</form>